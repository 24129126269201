 /* src/Components/IMap.css */

 .zoom_outer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  overflow: hidden;
}

#zoom {
    width: 100%;
    height: 0;/* Corrije puntos */
    transform-origin: 0px 0px;
    transform: scale(5) translate(0px, 0px);
    cursor: grab;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio  Corrije puntos*/
  }

  div#zoom > img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  /* Animación para el contenedor de la información */
  @keyframes fadeInScale {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .point {
    position: absolute;
    width: 30px; /* Ajusta el tamaño según lo necesites */
    height: 30px; /* Ajusta el tamaño según lo necesites */
    background-image: url('../../public/3.png'); /* Ruta de la imagen */
    background-size: cover;
    background-position: center;
    border-radius: 50%; /* Mantén el borde redondeado para que coincida con la imagen */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeInScale 1s ease-in-out;
  }
  
  .point:hover {
    transform: scale(1.5);
    width: 32px; /* Ajusta el tamaño según lo necesites */
    height: 32px; /* Ajusta el tamaño según lo necesites */
    
  } 
 