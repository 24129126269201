/* Fondo con imagen y capa transparente */
.auth-page {
  background-image: url('https://keystoneacademic-res.cloudinary.com/image/upload/f_auto/q_auto/g_auto/c_fill/w_1280/element/19/199558_Optimized-199503_cover_photo-marioLaserna.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.auth-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); /* Capa blanca transparente */
  z-index: 0;
}

/* Contenedor del formulario */
.auth-container {
  max-width: 320px;
  width: 90%;
  padding: 30px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

/* Logo */
.auth-logo {
  width: 250px;
  margin-bottom: 15px;
}

/* Descripción */
.auth-description {
  margin-bottom: 20px;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  color: #555;
}

h2 {
  margin-bottom: 20px;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

/* Agrupación de campos de entrada */
.input-group {
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #777;
  font-size: 0.9rem;
}

input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

/* Botones */
button.login-button {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  padding: 12px;
  background-color: #2a57e9;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button.login-button:hover {
  background-color: #274bc4;
}

button.toggle-button {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  padding: 12px;
  background-color: #2a57e954;
  color: rgb(0, 17, 95);
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

button.toggle-button:hover {
  background-color: #2a57e994;
}

/* Mensajes de error */
.error {
  color: red;
  margin-bottom: 10px;
}

/* Responsividad */
@media (min-width: 768px) {
  .auth-container {
    max-width: 500px;
    padding: 40px;
  }

  .auth-logo {
    width: 200px;
  }

  .auth-description {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .auth-container {
    width: 90%;
    padding: 20px;
  }

  .auth-logo {
    width: 160px;
  }

  .auth-description {
    font-size: 0.9rem;
  }
}
