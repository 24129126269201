/* Estilos del encabezado */
.header {
  background-color: #f7f7f7;
  color: #333333;
  font-family: 'Ubuntu', sans-serif;
  padding: 10px 20px;
  position: relative;
  z-index: 100;
}

.header-content {
  display: flex;
  align-items: center;
}

.hamburger-menu {
  font-size: 2em;
  background: none;
  border: none;
  cursor: pointer;
}

.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 15px;
}

.LogoHeader {
  width: auto;
  height: 60px;
}

/* Estilos de la barra lateral */
.nav {
  position: fixed;
  top: 0;
  left: -300px;
  height: 100%;
  width: 300px;
  background-color: #f7f7f7;
  transition: left 0.3s ease;
  overflow-y: auto;
  z-index: 999;
  display: flex;
  flex-direction: column;
}

.nav.open {
  left: 0;
}

.close-menu {
  font-size: 1.5em;
  background: none;
  border: none;
  color: #000;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.nav-list {
  list-style-type: none;
  padding: 100px 20px 20px 20px; /* Ajustado el padding-top */
  margin: 0;
  flex-grow: 1;
}

.nav-item {
  margin-bottom: 15px;
}

.nav-link {
  background-color: transparent;
  border: none;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.2em;
  color: #000;
  padding: 10px 0;
  cursor: pointer;
  transition: color 0.3s ease;
  width: 100%;
  text-align: left;
}

.nav-link:hover {
  color: #00d9ff;
}

.reset-button {
  font-weight: bold;
  color: #f44336;
}

.reset-button:hover {
  color: #d32f2f;
}

.profile-section {
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.user-email {
  font-size: 1em;
  margin-bottom: 10px;
  color: #333;
  font-family: 'Ubuntu', sans-serif;
  word-wrap: break-word;

}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f;
}

/* Estilos Responsivos */
@media (max-width: 768px) {
  .LogoHeader {
    height: 60px;
  }

  .nav {
    width: 230px;
    left: -230px;
  }

  .nav.open {
    left: 0;
  }

  .hamburger-menu {
    font-size: 2em;
  }
  .nav-link {

    padding: 3px 0;
  }
}
