@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');
* {
  padding: 0;
  margin: 0;
  outline: 0;
  overflow: hidden;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


.header {
  background-color: #f7f7f7;
  color: #333333;
  font-family: 'Ubuntu', sans-serif;
  padding: 10px 20px;
  position: relative;
  z-index: 1000; /* Asigna un z-index alto */
}



