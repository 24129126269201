@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.tags {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tag {
  display: inline-block;
  background-color: #cacaca;
  color: #000000;
  padding: 5px 10px;
  margin: 5px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 0.9em;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.info-box {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5%;
  padding: 20px;
  z-index: 1000;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  animation: fadeInScale 0.5s ease-in-out;
}

.info-box h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.info-box .rating {
  margin-bottom: 10px;
}

.info-box .description {
  word-wrap: break-word;
  max-width: 100%;
  margin-bottom: 10px;
}

.info-box .close-btn {
  display: block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.info-box .close-btn:hover {
  background-color: #0056b3;
}

.rating {
  font-size: 24px;
}

.star {
  color: #ccc;
}

.filled {
  color: gold;
}

.comment-list {
  list-style: none;
  padding: 0;
}

.comment {
  background-color: #f2f2f2;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.comentarios-section {
  margin-top: 20px;
  max-height: 80px; /* Altura máxima del contenedor */
  width: auto; /* Ancho dinámico ajustado al contenedor */
  overflow-y: auto; /* Scroll vertical si el contenido excede la altura */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Título de la sección de comentarios */
.comentarios-section h3 {
  margin-bottom: 10px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

/* Lista de comentarios */
.comentarios-section ul {
  list-style: none;
  padding: 0;
  margin-bottom: 10px;
}

/* Estilo para cada comentario */
.comentarios-section li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 8px;
  word-wrap: break-word;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Estilo del área de texto para agregar comentarios */
.comentario-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
  font-size: 14px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Botón para agregar comentario */
.add-comment-btn {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  background-color: #2a57e9;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-comment-btn:hover {
  background-color: #274bc4;
}

/* Mensaje de error */
.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Personalización de la barra de desplazamiento */
.comentarios-section::-webkit-scrollbar {
  width: 8px;
}

.comentarios-section::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.comentarios-section::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

/* Ajuste de etiquetas en dispositivos pequeños */
@media (max-width: 600px) {
  .info-box {
    width: 90%;
    padding: 10px;
    border-radius: 10px;
  }

  .info-box h1 {
    font-size: 1.5rem;
  }

  .info-box .close-btn {
    padding: 6px 12px;
  }

  .rating {
    font-size: 20px;
  }

  .nav-list {
    display: block;
    margin: 5px auto;
    font-size: 0.8em;
    text-align: center;
  }
}

small {
  color: #818181;
}
